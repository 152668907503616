import { memo, useRef, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/storeTypes';
import { useMatch, useNavigate } from 'react-router-dom';
import MenuHeader from '../components/MenuHeader';
import { DietFilterModalWrapper, UpsellModalWrapper } from '../../../helpers/modalHelpers';
import { historyMW } from '../../../helpers/routingHelpers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PopupBannerController from '../../../components/PopupBannerController';
import Notification from '../components/Notification';
import Layout from '../../../components/Layout';
import ItemMenu from '../components/ItemMenu';
import { setCollectionTime, setHasSeenBasket } from '../../../store/sessionSlice';
import { useConfig } from '../../../helpers/useConfig';
import { valueWithAppliedRewards } from '../../../helpers/basketHelpers';
import BasketIcon from '../../../img/icons/Basket.svg';
import { useMostVisibleElement } from '../../../hooks/useMostVisibleElement';
import BackMenuButton from '../../../components/BackMenuButton';
import RewardController from '../../../components/loyalty/RewardController';

function isMobile() {
  const match = window.matchMedia || window.msMatchMedia;
  if (match) {
    const mq = match("(pointer:coarse)");
    return mq.matches;
  }
  return false;
}

const isBrowserSimulation = () => {
  return isMobile() && navigator.maxTouchPoints === 1;
};

const Menu = memo(({
  filters,
  categories,
  isLoadingCategories,
  selectedCategory,
  setSelectedCategory,
  navigateTo,
  addItem,
  removeItem,
  notification,
  setNotification,
  refreshBasket,
  inApp,
  refs
}) => {
  const { width } = useWindowDimensions();
  const [dietModalIsOpen, setDietModalIsOpen] = useState(false);
  const [upsellModalOpen, setUpsellModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const [isBackButtonOverlapping, setIsBackButtonOverlapping] = useState(window.innerWidth < 1500);
  const restaurantId = useAppSelector(state => state.session.restaurantId);
  const freshRelevanceInitialised = useAppSelector(state => state.session.freshRelevanceInitialised);
  const restaurant = useAppSelector(state => state.session.restaurant);
  const isTabletScreen = useAppSelector(state => state.session.isTabletScreen);
  const appApproved = useAppSelector(state => state.session.customer?.appApproved);
  const loyalty = useAppSelector(state => state.session.features?.loyalty);
  const basket = useAppSelector(state => state.basket);
  const showCalories = useAppSelector(state => state.menu.showCalories);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const config = useConfig();
  const pageRef = useRef(null);
  const categoryRefs = useRef([]);
  const subCategoryHeaderRefs = useRef([]);
  const subCategoryRefs = useRef([]);
  const heroSectionRef = useRef(null);
  const [prevCat, setPrevCat] = useState(selectedCategory);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const isCustomisePage = useMatch('/:journey/menu/:restaurantId/customise/:itemid');

  const handleSetSelectedCategory = useCallback((index) => {
    if (selectedCategory == index) return;
    setSelectedCategory(index);
  });

  useMostVisibleElement(categoryRefs.current, handleSetSelectedCategory, categoryRefs.current.every((ref) => ref !== null), refs, isTabletScreen, isBackButtonOverlapping, inApp);
  useMostVisibleElement(subCategoryRefs.current[selectedCategory], setSelectedSubCategory, subCategoryRefs.current.every((ref) => ref !== null), refs, isTabletScreen, isBackButtonOverlapping, inApp);

  const findItemQuantity = (id, isBundle = false) => {
    const items = isBundle ? basket.bundles.filter(el => el.name === id) : basket.items.filter(el => el.id === id);
    return items.reduce((acc, cur) => acc += cur.quantity, 0);
  };

  useLayoutEffect(() => {
    if (!prevCat && prevCat !== 0) {
      setPrevCat(selectedCategory);
      return;
    }
    if (prevCat > selectedCategory) {
      setSelectedSubCategory(subCategoryRefs.current[selectedCategory].length - 1);
      // This ensures the last subcategory is preselected when scrolling up
      // Also hides the subcategories while the new set renders so we don't show them in the wrong place - MV
    }
    else {
      setSelectedSubCategory(0);
    }
    setPrevCat(selectedCategory);
  }, [selectedCategory]);

  const scrollTo = (titleRef) => {
    const isSimulation = isBrowserSimulation();
    const topPadding = 20;
    const navHeight = refs.current.navRef?.clientHeight ?? 100;
    const backButtonHeight = isBackButtonOverlapping && !inApp ? refs.current.backButtonRef?.children[0]?.clientHeight || 64 : 0;
    const headerHeight = isTabletScreen ? 0 : refs.current.headerRef?.clientHeight ?? 172;

    const verticalOffset = navHeight + backButtonHeight + headerHeight + (isTabletScreen && !inApp ? 0 : topPadding);

    const currentScrollY = isSimulation
      ? document.documentElement.scrollTop
      : window.scrollY;

    let newScroll = currentScrollY + titleRef.getBoundingClientRect().top - verticalOffset;

    window?.scrollTo({ top: newScroll });
  };

  const scrollToCategory = (index, allowScrollToSelected = false) => {
    if (selectedCategory == index && !allowScrollToSelected) return;
    const categoryTitle = subCategoryHeaderRefs.current[index]?.[0];
    if (!categoryTitle) return;
    scrollTo(categoryTitle);
    setSelectedSubCategory(0);
    setSelectedCategory(index);
  };

  const scrollToSubCategory = (index) => {
    if (index == 0) {
      scrollToCategory(selectedCategory, true);
    } else {
      const subCategoryTitle = subCategoryHeaderRefs?.current[selectedCategory]?.[index + 1];
      if (!subCategoryTitle) return;
      scrollTo(subCategoryTitle);
      setSelectedSubCategory(index);
    }
  };

  const handleBack = () => {
    historyMW.push('', basket.isDelivery, navigate);
  };

  useEffect(() => {
    dispatch(setHasSeenBasket(false));
    dispatch(setCollectionTime(''));
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'NAV_BAR', payload: 'true' }));
  }, []);

  useEffect(() => {
    if (isInitialRender) {
      // Don't scroll on first render
      setIsInitialRender(false);
    } else {
      scrollToCategory(selectedCategory, true);
    }
  }, [filters]);

  useEffect(() => {
    const handleResize = () => {
      if (isBackButtonOverlapping != (window.innerWidth < 1500)) {
        setIsBackButtonOverlapping(!isBackButtonOverlapping);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isBackButtonOverlapping]);

  return (
    <div ref={pageRef}>
      <Layout
        refs={refs}
        backgroundColour='#3AAA20'
        ctaCopy={basket.isDelivery ? '' : 'Change restaurant'}
        subtitle={
          basket.isDelivery && basket.delivery ?
            `Estimated delivery time: ${basket?.delivery?.deliveryQuote.duration?.begin} - ${basket?.delivery?.deliveryQuote.duration?.end} mins` :
            `You are ordering for collection${restaurant && restaurant.name ? ' from ' + restaurant.name + ' restaurant.' : '.'}`
        }
        backButton={handleBack}
        extraVerticalPadding
        allergens={true}
        isFluid={true}
        heroPaddingSize='is-xxsmall'
        isSmallerTitle
        customStyles={'is-menu'}
        heroSectionRef={heroSectionRef}
      >
        {!inApp &&
          <BackMenuButton refs={refs} handleBack={handleBack} />
        }
        {
          !isTabletScreen &&
          <MenuHeader
            refs={refs}
            menuCategories={categories}
            isLoadingCategories={isLoadingCategories}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            screenWidth={width}
            setDietModalIsOpen={setDietModalIsOpen}
            filter={filters}
            inApp={inApp}
            scrollToCategory={scrollToCategory}
            scrollToSubCategory={scrollToSubCategory}
          />
        }
        {loyalty && appApproved &&
          <div className="container">
            <RewardController inApp={inApp} />
          </div>}
        <div ref={r => refs.current.menuContainerRef = r} className='container' style={{ paddingBottom: '1rem', zIndex: 250 }} >
          <section className="menu menu-wrapper">
            <div className='is-flex-wrap-wrap is-align-content-stretch' style={{ width: '100%' }}>
              <ItemMenu
                categoryRefs={categoryRefs}
                subCategoryHeaderRefs={subCategoryHeaderRefs}
                subCategoryRefs={subCategoryRefs}
                menuCategories={categories}
                basket={basket}
                navigateTo={navigateTo}
                addItem={addItem}
                removeItem={removeItem}
                findItemQuantity={findItemQuantity}
              />
            </div>
            {
              showCalories &&
              <div className={`nutrition-note ${inApp && basket?.numberOfItems ? 'in-app-with-basket' : ''}`}>
                <span>Adults need around 2000 kcal per day.</span>
              </div>
            }
          </section>
        </div>
        {
          notification &&
          <Notification
            refs={refs}
            notification={notification}
            setNotification={setNotification}
            screenWidth={width}
          />
        }
        <div ref={r => refs.current.footerSpacer = r} style={{ height: 200, width: '100%' }} />
        {
          isTabletScreen &&
          <MenuHeader
            refs={refs}
            menuCategories={categories}
            isLoadingCategories={isLoadingCategories}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            screenWidth={width}
            setDietModalIsOpen={setDietModalIsOpen}
            filter={filters}
            inApp={inApp}
            scrollToCategory={scrollToCategory}
            scrollToSubCategory={scrollToSubCategory}
          />
        }
        {
          basket.numberOfItems && !isCustomisePage ?
            <PopupBannerController refs={refs} basket={basket} screenWidth={width} hide isMenu justifyEnd>
              <button
                onClick={() => {
                  if (!freshRelevanceInitialised) { setUpsellModalOpen(true); }
                }}
                className={'tobasket-btn btn btn-primary w100-mobile'}
                data-testid={'tobasket'}
              >
                <img alt='Basket Icon' src={BasketIcon} />
                {`Checkout - ${config?.CURRENCY_SYMBOL}${valueWithAppliedRewards(basket?.subTotal - basket?.voucherReduction, basket?.discounts).toFixed(2)}`}
              </button>
            </PopupBannerController>
            : null
        }
        <DietFilterModalWrapper
          inApp={inApp}
          dietModalIsOpen={dietModalIsOpen}
          setDietModalIsOpen={setDietModalIsOpen}
          screenWidth={width}
        />
        <UpsellModalWrapper
          upsellModalOpen={upsellModalOpen}
          setUpsellModalOpen={setUpsellModalOpen}
          screenWidth={width}
          addItem={addItem}
          removeItem={removeItem}
          navigateTo={navigateTo}
          restaurantID={restaurantId}
          refreshBasket={refreshBasket}
          inApp={inApp}
        />
      </Layout>
    </div>
  );
});

Menu.displayName = 'Menu'
export default Menu;
